<template>
    <vs-card>
      <div slot="header">
        <h3>
          {{title}}
        </h3>
      </div>
          <quillEditor :pages="pages"></quillEditor><br>
           <div>
        <vs-row vs-justify="flex-end">
              <vs-button color="success" @click="savePageDetails">Save</vs-button>
              <vs-button color="danger" style="margin-left:5px;" @click="$router.replace('/admin/cms-pages')">Cancel</vs-button>
        </vs-row>
          </div>
    </vs-card>
</template>

<script>

import { mapActions } from 'vuex'; 
import quillEditor from "../Editor";
import moment from "moment";

export default {
  name: 'pageViewEditDetail',
  components: {
    quillEditor
  },
  data:()=>({
    pages:{
      content:''
    },
    title:''
}),
  methods:{
    ...mapActions('page', ['fetchPageDetails','updatePageDetails']),
    getPageDetail(id) {
      let self = this;
      this.fetchPageDetails(id).then(res => {
        this.pages.content = res.data.data.content
        this.title= res.data.data.title
      })
    },
    savePageDetails(){
      let id = this.$route.params.id
      let info = this.pages
      this.updatePageDetails({id,info}).then(res => {
        this.$vs.notify({
              title: 'Update Page Content',
              text: res.data.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'success'
            })
      })
    }
  },
  created() {
      let pageId = this.$route.params.id;
      this.getPageDetail(pageId);
  }
}
</script>
<style>
.ql-editor{
    min-height:200px;
}
</style>